import { Form, Button } from "react-bootstrap";

const OrderBy = () => {
  return (
    <>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Ordernar por</Form.Label>
        <Form.Select size="sm" defaultValue={"publicdial"}>
          <option value="1">Fecha de publicación más reciente</option>
          <option value="2">Fecha de publicación más antigua</option>
          <option value="3">Resultados más pertinentes</option>
        </Form.Select>
        <Button variant="outline-secondary" className="my-2  btn-sm w-100 ">
          Reestablecer
        </Button>
      </Form.Group>
    </>
  );
};

export default OrderBy;
