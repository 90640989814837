import { Container, Nav, Navbar, NavDropdown, ListGroup, ProgressBar } from "react-bootstrap";
import { DataContext } from "../components/DataContext";
import React, { useContext } from "react";

import Historial from "./SideBar/Contents/Historial";
import Filters from "./SideBar/Contents/Filters";
import OrderBy from "./SideBar/Contents/OrderBy";

const MenuNavMobile = ({ setUser }) => {
	const now = 80;
	const { demoVersion } = useContext(DataContext);

	const handleLogout = () => {
		setUser([]);
	};

	return (
		<Navbar id="navbarMobile" collapseOnSelect expand="lg" bg="white" variant="light">
			<Container>
				<Navbar.Brand href="#home">
					{/* <img src="images/logo_sherlock_gris_oscuro.svg" alt="logo-sherlock" title="logo-sherlock" height={"30px"} /> */}
					<img src="images/logo_eldial.svg" alt="logo-sherlock" title="logo-sherlock" height={"30px"} />
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="responsive-navbar-nav" />
				{!demoVersion && (
					<Navbar.Collapse id="responsive-navbar-nav">
						<Nav className="me-auto">
							<NavDropdown title="Usuario" menuVariant="light" autoClose="outside">
								<NavDropdown.Item className="opcionesUsuario">
									<ListGroup variant="flush">
										<ListGroup.Item>
											<i className="bi bi-arrow-right-circle-fill"></i> eldial@eldial.com
										</ListGroup.Item>
										<ListGroup.Item>
											<i className="bi bi-arrow-right-circle-fill"></i> Preguntas usuario: 400
										</ListGroup.Item>
										<ListGroup.Item>
											<i className="bi bi-arrow-right-circle-fill"></i> Uso total: 800/1000
											<ProgressBar now={now} label={`${now}%`} variant="secondary" className="mt-2" />
										</ListGroup.Item>
									</ListGroup>
								</NavDropdown.Item>
								<NavDropdown.Divider />
								<NavDropdown.Item onClick={() => handleLogout()}>
									<i className="bi bi-power"></i> Cerrar sesión
								</NavDropdown.Item>
							</NavDropdown>
							<NavDropdown title="Historial" menuVariant="light" autoClose="outside">
								<NavDropdown.Item className="opcionesUsuario breakSpaces">
									<Historial />
								</NavDropdown.Item>
							</NavDropdown>
							<NavDropdown title="Filtros" menuVariant="light" autoClose="outside">
								<NavDropdown.Item className="opcionesUsuario">
									<OrderBy />
									<Filters />
								</NavDropdown.Item>
							</NavDropdown>
						</Nav>
					</Navbar.Collapse>
				)}
			</Container>
		</Navbar>
	);
};

export default MenuNavMobile;
