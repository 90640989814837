import React, { useState, useContext } from "react";
// import { HiOutlineLightBulb } from "react-icons/hi";
import { Button, Form, InputGroup, Row, Col } from "react-bootstrap";
import { DataContext } from "../components/DataContext";

function SearchBar() {
	const { process, dataResults, setResultsAlert } = useContext(DataContext);
	const [question, setQuestion] = useState("");
	const [showSpinner, setShowSpinner] = useState(false);
	const [disabledButton, setDisabledButton] = useState(false);

	const handleChange = (e) => {
		const trimQuestion = e.target.value.trim();

		if (trimQuestion.length === 0) {
			e.target.value = trimQuestion;
		}

		setQuestion(trimQuestion);
	};

	const handleKeyPress = (e) => {
		if (e.key === "Enter") {
			document.getElementById("button-addon2").click();
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		console.clear();
		console.log("handleSubmit");
		console.log({ e });
		setResultsAlert(false);
		if (!question) return;

		setShowSpinner(true);
		setDisabledButton(true);
		console.log(question);

		await process(question);
		setShowSpinner(false);
		setDisabledButton(false);
	};

	const spinner = <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>;

	return (
		<>
			<Row className="py-4 px-3 border-bottom">
				<Col sm={12} className="">
					<Form onSubmit={handleSubmit}>
						<InputGroup>
							<Form.Control className="border-secondary" placeholder="Escribe una pregunta..." aria-label="question" aria-describedby="basic-addon2" type="text" onChange={handleChange} onKeyDown={handleKeyPress} name="question" required />
							<Button type="submit" variant="secondary" id="button-addon2" disabled={disabledButton}>
								{showSpinner ? spinner : <i className="bi bi-search"></i>}
							</Button>
						</InputGroup>
					</Form>
				</Col>
				{/* {dataResults.length > 0 && (
          <Col sm={12} md={2} className="mt-2 mt-md-0 ps-md-0">
            <Button variant="outline-secondary" id="button-addon2" className="w-100 ">
              Analizar con IA <HiOutlineLightBulb className="fs-5 pb-1" />
            </Button>
          </Col>
        )} */}
			</Row>
		</>
	);
}

export default SearchBar;
