import { useState } from "react";
import { Form, Button, Container, Image } from "react-bootstrap";
import "./login.css";
import { config } from "../../config.js";
function Login({ setUser }) {
	const [userId, setUserId] = useState("");
	const [password, setPassword] = useState("");
	const [error, setError] = useState(false);
	const [errorMessage, setErrorMessage] = useState([]);

	const handleUserId = (event) => {
		setUserId(event.target.value);
	};

	const handlePassword = (event) => {
		setPassword(event.target.value);
	};

	const handleSubmit = async (event) => {
		event.preventDefault();

		const login = await postLogin({ userId, password });

		if (login.error) {
			setError(true);
			setErrorMessage(login.error.message);
			return;
		}

		setUser(userId);

		console.log("LOGIN>>> ", login);

		return true;
	};

	const postLogin = async (data) => {
		try {
			const post = await fetch(`${config.api[config.env]}/login`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					username: data.userId,
					password: data.password,
				}),
			});

			const response = await post.json();

			return response;
		} catch (error) {
			console.log(error);
		}
	};

	const errorDiv = () => {
		return (
			<div className="alert alert-danger py-2 mt-0 mb-2" role="alert">
				{errorMessage}
			</div>
		);
	};

	return (
		<>
			<div className="form-signin w-100 m-auto">
				<Form className="text-center" onSubmit={handleSubmit}>
					<Image className="mb-4 mx-auto" src="https://app.sherlocklegal.com.ar/images/logo_sherlock_gris_oscuro.svg" alt="" width="200px" />
					<p className="h4 mb-3 text-muted border-top pt-4">¡Bienvenido!</p>

					<div className="form-floating text-start mb-2">
						<Form.Control type="text" className="form-control-sm" id="floatingInput" onChange={handleUserId} required />
						<Form.Label htmlFor="floatingInput">Usuario</Form.Label>
					</div>
					<div className="form-floating text-start mb-0">
						<Form.Control type="password" className="form-control-sm mb-2" id="floatingPassword" onChange={handlePassword} required />
						<Form.Label htmlFor="floatingPassword">Contraseña</Form.Label>
					</div>

					{error ? errorDiv() : ""}

					<Button className="w-100 btn btn text-white border-0 bg-grey-primary hover-grey-secondary" type="submit">
						INGRESAR
					</Button>
				</Form>

				<Container className="text-center mt-5 small">
					{/* <a href="#" className="text-decoration-none text-grey-primary">
						¿No tienes cuenta? Registrate
					</a> */}
					<p className="text-muted small pt-2 pb-0 mb-0">Sherlock legal © 2023.</p>
					<p className="text-muted small pb-0 mb-0">Todos los derechos reservados.</p>
				</Container>
			</div>
		</>
	);
}

export default Login;
