import { ListGroup } from "react-bootstrap";

const Historial = () => {
  return (
    <>
      <ListGroup variant="flush" className="px-1 small">
        <ListGroup.Item className="small py-1 px-0">
          <i className="bi bi-dot"></i>Quien responde por los daños causados por un perro?
        </ListGroup.Item>
        <ListGroup.Item className="small py-1 px-0">
          <i className="bi bi-dot"></i>Los abuelos deben alimentos a los nietos?
        </ListGroup.Item>
        <ListGroup.Item className="small py-1 px-0">
          <i className="bi bi-dot"></i>Las filtraciones de humedad producidas en departamentos causan daño moral?
        </ListGroup.Item>
        <ListGroup.Item className="small py-1 px-0">
          <i className="bi bi-dot"></i>Debo pagar el monotributo si estoy inactivo?
        </ListGroup.Item>
      </ListGroup>
    </>
  );
};

export default Historial;
