import React, { useContext } from "react";
import { DataContext } from "./DataContext";

const EmptyScreen = () => {
  const { dataResults } = useContext(DataContext);

  return (
    <>
      {dataResults.length === 0 ? (
        <div id="emptyScreen">
          <div className="text-center">
            <p className="lead"> </p>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default EmptyScreen;
