import React, { createContext, useState } from "react";
import { config } from "../config";
export const DataContext = createContext();

export function DataProvider({ children }) {
	const [dataResults, setDataResults] = useState([]);
	const [resultsAlert, setResultsAlert] = useState(false);
	const [dichotomousQuery, setDichotomousQuery] = useState(false);

	// ################## PARA VERSIÓN DEMO, SETEAMOS demoVersion ########################
	// ############## Y RENDERIZAMOS COMPONENTES DIFERENTES PARA EL DR ###################
	const demoVersion = window.location.href.includes("demo") ? true : false;

	const process = async (question) => {
		setDataResults([]);
		const motor_sherlock_response = await sherlock_query(question);

		setDataResults(motor_sherlock_response);
	};

	const sherlock_query = async (question) => {
		try {
			const fetch_sherlock = await fetch(`${config.api[config.env]}?query=${question}`);
			const sherlock_response = await fetch_sherlock.json();

			console.log("SHERLOCK RESPONSE", sherlock_response);

			//VALIDACION PARA MOSTRAR MENSAJE DE ERROR
			if (sherlock_response.error) {
				const { status, message } = sherlock_response.error;
				console.log("ERROR >>", sherlock_response.error);

				if (status >= 400 && status < 500) {
					setResultsAlert({ type: "error", message });
				} else {
					setResultsAlert({ type: "error", message: "Ocurrió un error al procesar la consulta, intentá nuevamente." });
				}
				return [];
			}
			let results = sherlock_response.results;

			// Filtrar resultados que no tengan palabras faltantes
			// RESULTADOS CON PALABRAS FALTANTES NO SE MUESTRAN EN VERSIÓN DEMO
			if (demoVersion) results = results.filter((result) => result.missing_words.length === 0);

			if (!results || results.length === 0) {
				console.log("SIN RESULTADOS >>", sherlock_response);
				setResultsAlert({ type: "sin_resultados", message: "No se encontraron resultados. Intentá extendiendo o reformulando tu pregunta." });
				return [];
			}
			const keywords = sherlock_response.keywords;

			// Obtener array con todas las familia de palabras_clave
			let familias = [];
			const familias_palabras = keywords.map((keyword) => keyword.wordFamily).flat();

			familias_palabras.forEach((ele) => familias.push(ele.word));

			// Resaltar familia de palabras en titulo y cuerpo
			results.forEach((result) => {
				let matches = new Set();
				const regexp = (value) => new RegExp(`\\b${value}\\b`, `gi`);
				const replace_regexp = (value) => new RegExp(`\\b${value}\\b`, `gm`);

				familias.forEach((palabra) => {
					const title_match_results = result.title.match(regexp(palabra));
					title_match_results && title_match_results.forEach((match) => matches.add(match));

					const body_match_results = result.body.match(regexp(palabra));
					body_match_results && body_match_results.forEach((match) => matches.add(match));
				});
				matches = Array.from(matches);
				matches.sort((a, b) => b.length - a.length);
				matches.forEach((match) => {
					result.body = result.body.replace(replace_regexp(match), `<u><i>${match}</i></u>`);
					result.title = result.title.replace(replace_regexp(match), `<u><i>${match}</i></u>`);
					result.relevant_fragments.forEach((fragment) => {
						fragment.text = fragment.text.replace(replace_regexp(match), `<u><i>${match}</i></u>`);
					});
				});

				//REEMPLAZAR EN EL LINK LA URL DE ELDIAL MOVIL POR LA DE SHERLOCK
				result.link = result.link.replace("https://www.eldial.com/eldial_movil/resultados_whatsapp_eldial_movil.asp", "https://www.eldial.com/resultados_sherlock.asp");
			});

			// Seteo el estado para saber si la pregunta es por si o por no
			// Con esto decido mostrar el grafico de resultados o no
			setDichotomousQuery(sherlock_response.query.dichotomous);

			return results;
		} catch (error) {
			console.log("ERROR EN SHERLOCK", error);
			setResultsAlert({ type: "error", message: "Ocurrió un error al procesar la consulta, intentá nuevamente." });
			return [];
		}
	};
	return <DataContext.Provider value={{ dataResults, setDataResults, process, resultsAlert, setResultsAlert, dichotomousQuery, demoVersion }}>{children}</DataContext.Provider>;
}
