import React, { useContext } from "react";
import { Row, Col, ProgressBar } from "react-bootstrap";
import { DataContext } from "./DataContext";

function HorizontalChart() {
  const { dataResults } = useContext(DataContext);
  console.log("GRAFICO >>>>>  ", dataResults);

  const si = ((dataResults.filter((item) => item.yes_or_no_response === "si").length / dataResults.length) * 100).toFixed(1);
  const no = ((dataResults.filter((item) => item.yes_or_no_response === "no").length / dataResults.length) * 100).toFixed(1);
  const neutral = ((dataResults.filter((item) => item.yes_or_no_response === "neutral").length / dataResults.length) * 100).toFixed(1);

  const info = (
    <>
      <ProgressBar className="mt-4 mx-3 fw-bold " style={{ height: "25px" }}>
        <ProgressBar now={si} key={1} label={`${si}%`} className="background-si " />
        <ProgressBar now={no} key={2} label={`${no}%`} className="background-no " />
        <ProgressBar now={neutral} key={3} label={`${neutral}%`} className="bg-secondary " />
      </ProgressBar>
      <Row className="text-center mt-3 w-75 mx-auto small">
        <Col sm="12" md="4" className="small pb-1 border-bottom-si">
          RESULTADOS POR SI
        </Col>
        <Col sm="12" md="4" className="small pb-1 border-bottom-no">
          RESULTADOS POR NO
        </Col>
        <Col sm="12" md="4" className="small pb-1 border-bottom-neutral">
          NO CONCLUYENTE
        </Col>
      </Row>
    </>
  );
  return <>{dataResults.length > 0 && info}</>;
}

export default HorizontalChart;
