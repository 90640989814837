import React from "react";
import Modal from "react-bootstrap/Modal";
import parse from "html-react-parser";

const DocumentModal = ({ setShowDocumentModal, showDocumentModal, data }) => {
	return (
		<>
			<Modal scrollable={true} show={showDocumentModal} onHide={() => setShowDocumentModal(false)} aria-labelledby="modal-title" size="lg">
				<Modal.Header closeButton>
					<Modal.Title id="modal-title" className="h6 m-3">
						{data.origin === "doctrina" && (
							<p className="py-1 mb-0">
								<b>
									{data.author} - {new Date(data.date).toLocaleDateString()}
								</b>
							</p>
						)}
						{parse(data.title)}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body className="">
					<p className="justificarTexto small">{parse(data.body)}</p>
				</Modal.Body>
				<Modal.Footer className="py-0 px-0">
					<div className="text-md-end text-center w-100 px-md-3  mb-3">
						<a href={data.link} target="_blank" rel="noopener" className="text-decoration-none fst-italic small">
							Ver documento en elDial.com
						</a>
					</div>
					<p className="small text-secondary w-100 text-center m-0 py-2 bg-light">Sherlock legal © 2023 todos los derechos reservados.</p>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default DocumentModal;
