import { Form } from "react-bootstrap";

const Filters = () => {
  return (
    <>
      {/* ****************** OPCIONES TIPO DE DOCUMENTO ****************** */}
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Tipo de documento</Form.Label>
        <Form.Select size="sm" defaultValue={"publicdial"}>
          <option value="publicdial">JURISPRUDENCIA</option>
          <option value="cnciv">DOCTRINA</option>
          <option value="cntrab">MODELO</option>
          <option value="todas">TODOS</option>
        </Form.Select>
      </Form.Group>

      {/* ****************** GENERICOS ****************** */}
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Otro</Form.Label>
        <Form.Select size="sm" defaultValue={"publicdial"}>
          <option value="1">Opción</option>
          <option value="2">Opción</option>
          <option value="3">Opción</option>
          <option value="4">Opción</option>
        </Form.Select>
      </Form.Group>
    </>
  );
};

export default Filters;
