import React, { useState, useEffect } from "react";
import { Col, Row, Container } from "react-bootstrap";
import Filters from "./Contents/Filters";
import Historial from "./Contents/Historial";
import OrderBy from "./Contents/OrderBy";

const SideBarContainer = () => {
  const [showSideBar, setShowSideBar] = useState(false);
  const [sideBarContent, setSideBarContent] = useState(false);
  const [sideBarSize, setSideBarSize] = useState("");
  const [sidebarOpenBtnDisabled, setSidebarOpenBtnDisabled] = useState("disabled");

  const handleSideBar = (content, colSize) => {
    setSideBarContent(content);
    const showConditions = !showSideBar || (showSideBar && content !== sideBarContent);
    setSideBarSize(colSize);
    setShowSideBar(content ? showConditions : false);
  };

  useEffect(() => {
    setSideBarSize(showSideBar ? sideBarSize : "auto");
    setSidebarOpenBtnDisabled(showSideBar ? "" : "disabled");

    //25/01 COLOQUÉ EN EL ARRAY DE DEPENDENCIA sideBarSize
    // porque me lo sugería en un warning (funciona igual de bien sin él)
  }, [showSideBar, sideBarSize]);
  return (
    <Col id="sidebar" sm={sideBarSize} className="pe-2 " style={{ minWidth: "50px" }}>
      <Row className="h-100">
        <Col sm="auto" className="bg-white text-center border-end px-0 py-1 " style={{ width: "50px" }}>
          <i className={`bi bi-arrow-bar-left ${sidebarOpenBtnDisabled} btn m-0 rounded-0 btn-light border border-3 border-white ${""}`} onClick={() => showSideBar && handleSideBar(false)}></i>
          <i className="bi bi-sort-down btn m-0 rounded-0 btn-light border border-3 border-white" onClick={() => handleSideBar("orderBy", 3)}></i>
          <i className="bi bi-funnel btn  m-0 rounded-0 btn-light border border-3 border-white" onClick={() => handleSideBar("filters", 3)}></i>
          <i className="bi bi-clock btn m-0 rounded-0 btn-light border border-3 border-white" onClick={() => handleSideBar("history", 4)}></i>
        </Col>
        {showSideBar && (
          <Col className=" py-3 px-0  border-end w-100 ">
            <Container id="sidebar">
              {sideBarContent === "filters" && <Filters />}
              {sideBarContent === "history" && <Historial />}
              {sideBarContent === "orderBy" && <OrderBy />}
            </Container>
          </Col>
        )}
      </Row>
    </Col>
  );
};

export default SideBarContainer;
