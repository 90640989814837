import React from "react";
import { Toast, ToastContainer } from "react-bootstrap";

function SherlockToast({ showToast, toggleShowToast }) {
  return (
    <ToastContainer className="p-3 position-fixed" position="bottom-end">
      <Toast show={!showToast} onClose={toggleShowToast}>
        <Toast.Header closeButton={true}>
          <img src="images/cabeza_sherlock_oscura.svg" className="rounded me-2" alt="" height="30px" />
          <strong className="me-auto text-dark">Mensaje de Sherlock</strong>
          <small>7 mins ago</small>
        </Toast.Header>
        <Toast.Body>Es muy probable que la respuesta sea Si</Toast.Body>
      </Toast>
    </ToastContainer>
  );
}

export default SherlockToast;
