import { Alert } from "react-bootstrap";

function DocAnalysis({ text, factic_condition, setShowDocAnalisis }) {
  const alert_color_options = {
    si: "gpt3Response-si",
    no: "gpt3Response-no",
    neutral: "gpt3Response",
  };
  const alert_color = alert_color_options[factic_condition] || alert_color_options.neutral;
  return (
    <>
      <Alert onClose={() => setShowDocAnalisis(false)} closeVariant="white" dismissible className={`m-0 pt-3 pb-0 rounded-0 rounded-bottom text-center fst-italic  border-0 ${alert_color}`}>
        <p>{text} </p>
      </Alert>
    </>
  );
}

export default DocAnalysis;
