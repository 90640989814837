import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import MenuNav from "./components/MenuNav.jsx";
import MenuNavMobile from "./components/MenuNavMobile.jsx";
import SearchBar from "./components/SearchBar.jsx";
import MainContainer from "./components/MainContainer.jsx";
import { DataProvider } from "./components/DataContext.jsx";
import Login from "./components/Login/Login.jsx";
import { useState } from "react";

function App() {
	const [user, setUser] = useState([]);

	// *********** CLAVE DE ACCESO (TEMPORAL) ***********
	// let key = sessionStorage.getItem("key");
	// const validKey = `sherlock${new Date().getDate()}`;

	// while (key !== validKey) {
	// 	let promptKey = prompt("Por favor introduzca la clave de acceso:");
	// 	if (promptKey === validKey) {
	// 		key = promptKey;
	// 		sessionStorage.setItem("key", promptKey);
	// 	}
	// }
	// *********** FIN CLAVE DE ACCESO  ***********

	return (
		<>
			{!user.length ? (
				<Login setUser={setUser} />
			) : (
				<DataProvider>
					<Container id="MainContent" className="bg-white stikyMain ">
						<MenuNavMobile setUser={setUser} />
						<MenuNav setUser={setUser} />
						<SearchBar />
						<MainContainer />
					</Container>
				</DataProvider>
			)}
		</>
	);
}

export default App;
