import { Col, Row, Alert, Container } from "react-bootstrap";
import React, { useContext } from "react";
import SideBarContainer from "./SideBar/SideBarContainer";
import ResultCardContainer from "./Results/ResultCardContainer";
import Footer from "./Footer";
import HorizontalChart from "./HorizontalChart";
import EmptyScreen from "./EmptyScreen";
import { DataContext } from "../components/DataContext";

const MainContainer = () => {
  const { dataResults, resultsAlert, dichotomousQuery, demoVersion } = useContext(DataContext);
  const everyNeutral = dataResults.every((result) => result.yes_or_no_response === "neutral");

  return (
    <>
      <Row className=" d-flex " style={{ minHeight: "70vh" }}>
        {!demoVersion && <SideBarContainer />}
        <Col id="contentContainer">
          {resultsAlert && (
            <>
              <Container>
                <Alert className="mt-3" variant="danger">
                  {resultsAlert.message}
                </Alert>
              </Container>
            </>
          )}
          <EmptyScreen />
          {!everyNeutral && dichotomousQuery && <HorizontalChart />}
          <Container>{dataResults && <ResultCardContainer data={dataResults} />}</Container>
        </Col>
      </Row>
      <Footer />
    </>
  );
};

export default MainContainer;
