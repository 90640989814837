import React from "react";
import Modal from "react-bootstrap/Modal";
import parse from "html-react-parser";

const RelevantFragmentsModal = ({ setShowRelevantFragmentsModal, showRelevantFragmentsModal, data }) => {
	const { title, relevant_fragments } = data;
	return (
		<>
			<Modal scrollable={true} show={showRelevantFragmentsModal} onHide={() => setShowRelevantFragmentsModal(false)} aria-labelledby="example-custom-modal-styling-title" size="lg">
				<Modal.Header closeButton>
					<Modal.Title id="example-custom-modal-styling-title" className="h6 m-3">
						{data.origin === "doctrina" && (
							<p className="py-1 mb-0">
								<b>
									{data.author} - {new Date(data.date).toLocaleDateString()}
								</b>
							</p>
						)}
						{parse(title)}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body className="mx-3">
					{relevant_fragments.map((fragment) => (
						<p className="justificarTexto small border-bottom pb-3">{parse(fragment.text)}</p>
					))}
				</Modal.Body>
				<Modal.Footer>
					<p className="small text-muted mx-auto">Sherlock legal © 2023 todos los derechos reservados.</p>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default RelevantFragmentsModal;
