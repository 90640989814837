import React, { useState, useContext } from "react";
import parse from "html-react-parser";
import { Button, Card, Row, Col } from "react-bootstrap";
import AlertToast from "../AlertToast";
import DocumentModal from "../DocumentModal";
import RelevantFragmentsModal from "../RelevantFragmentsModal";
// import DocAnalysis from "./DocAnalysis";
import DocAnalysis from "../DocAnalysis";
import { DataContext } from "../DataContext";

const ResultCard = ({ result }) => {
	const { demoVersion } = useContext(DataContext);
	const [showDocumentModal, setShowDocumentModal] = useState(false);
	const [showRelevantFragmentsModal, setShowRelevantFragmentsModal] = useState(false);
	const [showToast, setShowToast] = useState(true);
	const toggleShowToast = () => setShowToast(!showToast);
	const [showDocAnalisis, setShowDocAnalisis] = useState(false);
	let origen = {
		publicdial: "Jurisprudencia",
		cnciv: "Cámara Nacional de Apelaciones en lo Civil",
		cntrab: "Cámara Nacional de Apelaciones del Trabajo",
		doctrina: "Doctrina",
	};

	let colorCard = {
		si: "background-si",
		no: "background-no",
		neutral: "bg-secondary",
	};

	const missingWordOpacity = result.missing_words.length ? " opacity-50" : "";

	const missingWordSpan = result.missing_words.map((word) => {
		return `<span className="bg-light small rounded-1 text-danger py-1 px-2 me-2 mt-0 text-decoration-line-through">${word}</span>`;
	});

	return (
		<>
			<Card className={`w-100 resultCard my-4  ${missingWordOpacity}`}>
				<Row className="me-md-0 marginCards">
					<Col sm="auto" className="d-flex align-items-center pe-0 cardBorderIndicator">
						<div className={colorCard[result.yes_or_no_response] || colorCard.neutral} style={{ width: "12px", height: "60%", borderRadius: "0 1rem 1rem 0" }}></div>
					</Col>

					<Col>
						<Row className="paddingLeftResponsive">
							<Col className="mx-auto col px-0r">
								<Card.Body>
									<Card.Title className="justifyText small h5">{parse(result.title)}</Card.Title>
									<div className="d-flex mb-3">
										<div className="me-auto">
											<span className=" small rounded-1 text-secondary py-1 px-2 me-2" style={{ backgroundColor: "#eaeced" }}>
												<b>{origen[result.origin] || result.origin}</b>
											</span>

											{result.origin === "doctrina" && (
												<span className="small rounded-1 text-secondary py-1 px-2 me-2" style={{ backgroundColor: "#eaeced" }}>
													<b>{new Date(result.date).toLocaleDateString()}</b>
												</span>
											)}

											{!demoVersion && (
												<span className=" small rounded-1 alert alert-info text-primary py-1 px-2 me-2">
													Score: <b>{result.score}</b>
												</span>
											)}
										</div>
									</div>

									{result.origin === "doctrina" && <Card.Text className="justifyText small fw-bold mb-1">{result.author}</Card.Text>}

									<Card.Text className="justifyText small">{parse(result.body.substring(0, 400))} ...</Card.Text>
									<div>{parse(missingWordSpan.join(""))}</div>
								</Card.Body>
							</Col>
							<Col sm={12} md={3} className="text-center my-auto borderLeftResponsive py-2">
								<Button variant="light" className="btn btn-sm w-100 my-2 border border-1" onClick={() => setShowRelevantFragmentsModal(true)}>
									Fragmentos relevantes
								</Button>
								<Button variant="light" className="btn btn-sm w-100 my-2 border border-1" onClick={() => setShowDocumentModal(true)}>
									Mostrar documento
								</Button>
								<Button variant="secondary" className="btn btn-sm w-100 my-2" onClick={() => setShowDocAnalisis(true)}>
									Analizar documento
								</Button>
								{/* <Button variant="outline-warning" className="btn btn-sm w-100 my-2" onClick={toggleShowToast}>
                  Calificar
                </Button> */}
								<a href={result.link} target="_blank" rel="noreferrer" className="text-decoration-none fst-italic small">
									Ver en elDial.com
								</a>
							</Col>
						</Row>
					</Col>
				</Row>
				{showDocAnalisis ? <DocAnalysis text={result.response} factic_condition={result.yes_or_no_response} setShowDocAnalisis={setShowDocAnalisis} /> : ""}
			</Card>
			<DocumentModal setShowDocumentModal={setShowDocumentModal} showDocumentModal={showDocumentModal} data={result} />
			<RelevantFragmentsModal setShowRelevantFragmentsModal={setShowRelevantFragmentsModal} showRelevantFragmentsModal={showRelevantFragmentsModal} data={result} />
			<AlertToast setShowToast={setShowToast} showToast={showToast} toggleShowToast={toggleShowToast} />
		</>
	);
};

export default ResultCard;
