import React from "react";
import { Row, Col } from "react-bootstrap";

const Footer = () => (
  <footer className="footer mx-auto ">
    <Row className="justify-content-center">
      <Col md={4}>
        <a href="https://sherlocklegal.com.ar/" target="_blank" rel="noreferrer">
          <img src="images/logo_sherlock_gris_oscuro.svg" alt="#" height="50px" />
        </a>
      </Col>
      <Col md={4} className="text-secondary  small">
        <h4 className="ms-4">Contáctanos</h4>
        <ul>
          <li>TEL / FAX: +54 11 4371-2806</li>
          <li>
            <a href="mailto:info@albrematica.com.ar">info@albrematica.com.ar</a>
          </li>
          <li>
            <a href="mailto:sherlock@albrematica.com.ar">sherlock@albrematica.com.ar</a>
          </li>
        </ul>
      </Col>
      <Col md={4} className="text-secondary  small">
        <h4 className="ms-4">Dirección / Horarios</h4>
        <ul>
          <li>Tucumán 1440 (1050) CABA.</li>
          <li>Buenos Aires, Argentina.</li>
          <li>Lun - Vie 09:00 hs a 18:00 hs</li>
        </ul>
      </Col>
    </Row>
    <div className="text-center border-top small text-muted pt-2 mx-0 px-0 w-100">
      <p>Sherlock legal © 2023 todos los derechos reservados.</p>
    </div>
  </footer>
);

export default Footer;
