import { Container, Nav, Navbar, NavDropdown, ListGroup, ProgressBar } from "react-bootstrap";
import React, { useContext } from "react";
import { DataContext } from "../components/DataContext";

function MenuNavBar({ setUser }) {
	const { demoVersion } = useContext(DataContext);
	const now = 80;

	const handleLogout = () => {
		setUser([]);
	};

	return (
		<Navbar id="navbarPc" className="bg-white flex-wrap mt-5 border-bottom">
			<Container>
				<Navbar.Brand href="#home">
					<img src="images/logo_eldial.svg" alt="logo-sherlock" title="logo-sherlock" height={"30px"} />
					{/* <img src="images/logo_sherlock_gris_oscuro.svg" alt="logo-sherlock" title="logo-sherlock" height={"30px"} /> */}
				</Navbar.Brand>
				<Navbar.Toggle />
				<Nav className="me-auto mt-2 mb-0"></Nav>
				<img className="py-0 mb-0 pe-3" src="images/logo_sherlock_gris_oscuro.svg" alt="logo-sherlock" title="logo-sherlock" height={"25px"} />
				{!demoVersion && (
					<NavDropdown title={<i className="bi bi-person-circle  py-0 ps-2 h4 mb-0 text-muted"></i>} menuVariant="light" autoClose="outside">
						<NavDropdown.Item className="opcionesUsuario">
							<ListGroup variant="flush">
								<ListGroup.Item>
									<i className="bi bi-arrow-right-circle-fill"></i> eldial@eldial.com
								</ListGroup.Item>
								<ListGroup.Item>
									<i className="bi bi-arrow-right-circle-fill"></i> Preguntas usuario: 400
								</ListGroup.Item>
								<ListGroup.Item>
									<i className="bi bi-arrow-right-circle-fill"></i> Uso total: 800/1000
									<ProgressBar now={now} label={`${now}%`} variant="secondary" className="mt-2" />
								</ListGroup.Item>
							</ListGroup>
						</NavDropdown.Item>

						<NavDropdown.Divider />
						<NavDropdown.Item onClick={() => handleLogout()}>
							<i className="bi bi-power"></i> Cerrar sesión
						</NavDropdown.Item>
					</NavDropdown>
				)}
			</Container>
		</Navbar>
	);
}

export default MenuNavBar;
