import ResultCard from "./ResultCard";

// TODO: REVISAR PARA INCLUIR PAGINACIÓN Y UNA OPCIÓN MAS EN LA CONFIG PARA DECICIR CUANTOS RESULTADOS POR PAG MOSTRAR
const CardsContainer = ({ data }) => {
  console.log({ data });
  const results = data.map((result) => <ResultCard result={result} />);

  return <>{results}</>;
};

export default CardsContainer;
